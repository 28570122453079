import React from 'react'
import { graphql } from 'gatsby'

import './styles/class-template.css'

import Img from 'gatsby-image'
import SEO from '../components/seo'
import Layout from '../components/layout'
import { TeachingClass } from '../lib/types'

interface Props {
  data: any
}

const ClassTemplate: React.FC<Props> = ({ data }) => {

  const tClass: TeachingClass = data.markdownRemark

  const { title, code, year, semester, cover } = tClass.frontmatter

  return (
    <>
      <SEO title={title} />
      <Layout>

        <div className="t-class-template">

          {cover && <Img className="t-class-template__cover" fluid={(cover as any).childImageSharp.fluid} alt={title} />}

          <header className="t-class-template__header">
            <h1>{title}</h1>
            <h4>Turma {code} -  {year}/{semester}</h4>
          </header>

          {/* Post html */}
          <div
            className="t-class-template__content"
            dangerouslySetInnerHTML={{ __html: tClass.html }} />

        </div>

      </Layout>
    </>
  )
}

export default ClassTemplate

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        code
        year
        semester
        cover {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`